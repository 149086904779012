<template>
  <v-app v-if="messageLoading">
    <!--  && picLoading -->
    <!-- <div id="navbar-lang">
      <div>Change language:</div>
      <v-select
        class="fit"
        :items="langs"
        v-model="locale"
        hide-details
        single-line
      >
        <template v-slot:selection="{ item }">
          <country-flag :country="item" size="normal" />
        </template>
        <template v-slot:item="{ item }">
          <country-flag :country="item" size="normal" />
        </template>
      </v-select>
    </div> -->
    <component :is="layout"> </component>
    <router-view />
  </v-app>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import EmptyLayout from "@/layouts/EmptyLayout";
import CountryFlag from "vue-country-flag";
// import store from './store'
import { mapState, mapMutations } from "vuex";
export default {
  data: () => ({
    locale: window.navigator.language.slice(0, 2),
    langs: ["ru", "gb"],
    textblock: [],
  }),

  async mounted() {
    if (this.locale !== "ru" && localStorage.locale == undefined) {
      this.$i18n.locale = "ru";
      this.locale = "ru";
    } else if (this.locale == "ru" && localStorage.locale == undefined) {
      this.$i18n.locale = "ru";
      this.locale = "ru";
    } else {
      this.$i18n.locale = localStorage.locale;
      this.locale = localStorage.locale;
    }
    await this.$store.dispatch("getTextblock");
    this.textblock = await this.$store.getters.getTextblock;
    this.$i18n.setLocaleMessage(this.locale, this.textblock[this.locale]);
    // this.loadp();
    await this.$store.dispatch("getAllPictureblocks");
    // await this.getPictureblock();
    await this.$store.dispatch("getAllLicenseblocks");
    await this.$store.dispatch("getAllArticleblocks");
    this.load();
  },
  watch: {
    locale: function (newValue) {
      if (newValue) {
        this.$i18n.locale = newValue;
        this.$i18n.setLocaleMessage(newValue, this.textblock[newValue]);
      }
    },
  },

  methods: mapMutations(["load", "loadp"], {}),

  computed: mapState({
    messageLoading: (state) => state.textblocks.messageLoading,
    picLoading: (state) => state.pictureblocks.picLoading,
    layout() {
      console.log();
      return (this.$route.meta.layout || "empty") + "-layout";
    },
  }),
  components: {
    MainLayout,
    EmptyLayout,
    CountryFlag,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500&display=swap");

.disp {
  display: none;
}
</style>
