<template>
  <v-carousel :show-arrows="true" height="500" hide-delimiter-background id="slider" cycle interval="4000">
    <v-carousel-item v-for="(slide1, i) in slides1" :key="'4' + i" :src="getImageUrlbyName('SliderBG4')">
      <div id="slidepromo">
        <div id="slidetext2promo">
          <h1 id="sliderpromotitle1">{{ $t("message.SliderPromoTitle") }}</h1>
          <div id="sliderpromodisc1">{{ $t("message.SliderPromoDisc") }}</div>
          <div id="btnpromocenter">
            <v-btn small outlined id="btnpromowhite" href=""> {{ $t("message.VideoTour") }}</v-btn>
            <v-btn small id="btnpromo" href="">{{ $t("message.BookADemo") }}</v-btn>
          </div>
        </div>
      </div>
    </v-carousel-item>
    <v-carousel-item v-for="(slide1, i) in slides1" :key="'1' + i" :src="getImageUrlbyName('SliderBG1')">
      <div id="slidecontent">
        <div id="slidetext1">
          <h1 id="slidertitle1">{{ $t("message.SliderSmarTruck") }}</h1>
          <div id="sliderdisc1">{{ $t("message.SliderSmarTruckText") }}</div>
          <div id="btn">
            <v-btn small id="btnwidewhite" @click="$router.push({ path: '/products/struck' })"> {{ $t("message.SliderKnowMore") }}</v-btn>
            <!-- <v-btn small id="btnwide" href="https://smartruck.ru/">{{ $t("message.SliderBuySystem") }}</v-btn> -->
            <v-dialog v-model="dialog" width="500" id="fb-bg">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" color="primary" elevation="2" outlined id="callback">{{ $t("message.SliderBuySystem") }}</v-btn>
              </template>
              <v-card min-height="500">
                <div id="feedback-marg">
                  <Feedback />
                </div>
              </v-card>
            </v-dialog>
          </div>
        </div>
        <img id="slideimg1" :src="getImageUrlbyName('SlideImg1')" alt="" />
      </div>
    </v-carousel-item>
    <v-carousel-item v-for="(slide2, i) in slides2" :key="'2' + i" :src="getImageUrlbyName('SliderBG2')">
      <div id="slidecontent">
        <div id="slidetext2">
          <h1 id="SPh1">{{ $t("message.SliderSmartProduction") }}</h1>
          <div id="sliderdisc1">
            {{ $t("message.SliderSmartProductionText") }}
          </div>
          <div id="btn">
            <v-btn small id="btnwidewhite" @click="$router.push('/products/sprod')">{{ $t("message.SliderKnowMore") }}</v-btn>
            <!-- <v-btn small id="btnblue" @click="$router.push('/products/sprod')">{{ $t("message.SliderBuySystem") }}</v-btn> -->
            <v-dialog v-model="dialog" width="500" id="fb-bg">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" color="primary" elevation="2" outlined id="callback">{{ $t("message.SliderBuySystem") }}</v-btn>
              </template>
              <v-card min-height="500">
                <div id="feedback-marg">
                  <Feedback />
                </div>
              </v-card>
            </v-dialog>
          </div>
        </div>
        <img id="slideimg2" :src="getImageUrlbyName('SlideImg2')" alt="" />
      </div>
    </v-carousel-item>
    <v-carousel-item v-for="(slide1, i) in slides1" :key="'3' + i" :src="getImageUrlbyName('SliderBG1')">
      <div id="slidecontent">
        <div id="slidetext1">
          <h1 id="slidertitle1">{{ $t("message.SliderSmarTeams") }}</h1>
          <div id="sliderdisc1">
            {{ $t("message.SliderSmarTeamsText") }}
          </div>
          <div id="btn">
            <v-btn small id="btnwidewhite" @click="$router.push({ path: '/products/steams' })">{{ $t("message.SliderKnowMore") }}</v-btn>
            <!-- <v-btn small id="btnwide" href="https://smarteams.ru/">{{ $t("message.SliderBuySystem") }}</v-btn> -->
            <v-dialog v-model="dialog" width="500" id="fb-bg">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" color="primary" elevation="2" outlined id="callback">{{ $t("message.SliderBuySystem") }}</v-btn>
              </template>
              <v-card min-height="500">
                <div id="feedback-marg">
                  <Feedback />
                </div>
              </v-card>
            </v-dialog>
          </div>
        </div>
        <img id="slideimg1" :src="getImageUrlbyName('SlideImg3')" alt="" />
      </div>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import Feedback from "../components/Feedback";
export default {
  components: {
    Feedback,
  },
  data() {
    return {
      slides1: [
        {
          src: "https://its.ooo/upload/iblock/c56/c566fa264ab12a8d07f0a10cde890df8.jpg",
        },
      ],
      slides2: [
        {
          src: "https://its.ooo/upload/iblock/874/87466d2f2ca69895780a58d61756ae29.jpg",
        },
      ],
    };
  },
  computed: {},
  methods: {},
};
</script>

<style>
#slider {
  margin-top: 100px;
}
#slidertitle1 {
  font-size: 32px;
  font-weight: 500;
}
#slidecontent {
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: space-between;
  padding-left: 20%;
  padding-right: 20%;
  padding-top: 60px;
}
#sliderdisc1 {
  font-size: 20px;
  font-weight: 400;
}
#sliderpromodisc1 {
  font-size: 20px;
  font-weight: 300;
}
#btnblue {
  background-color: #00569c;
  color: #e3e7ec;
  min-width: 160px;
  letter-spacing: 0.01em;
}
#btnwide {
  background-color: #00569c;
  color: #e3e7ec;
  min-width: 160px;
  font-size: 12px;
  letter-spacing: 0.01em;
}
#btnwidewhite {
  min-width: 160px;
  letter-spacing: 0.01em;
}
#SPh1 {
  font-size: 32px;
  font-weight: 500;
}
#btn {
  padding-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 24px;
  grid-column-gap: 16px;
  letter-spacing: 0.01em;
}
#btnpromowhite {
  max-width: 200px;
  min-height: 50px;
  /* background: none; */
  border: 2px solid #e3e7ec;
  color: white;
  font-size: 14px;
}
#btnpromo {
  max-width: 200px;
  min-height: 50px;
  margin-left: 40px;
  color: white;
  background-color: #1164a8;
  font-size: 14px;
}
#btnpromocenter {
  padding-top: 50px;
}
#slidetext1 {
  color: black;
}

#slidetext2 {
  color: rgb(255, 255, 255);
}
#slidetext2promo {
  color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 150px;
}

#slideimg1 {
  margin-left: 5%;
}

#slideimg2 {
  padding-top: 40px;
  margin-left: 5%;
}

#body {
  padding: 0;
  margin: 0;
  padding-top: 30px;
}

@media (max-width: 480px) {
  .slide {
    min-height: 390px;
    background: url("../assets/images/car1.jpg");
  }

  #slidecontent {
    position: relative;
    display: flex;
    text-align: left;
    align-items: center;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
  }

  #btnblue {
    background-color: #00569c;
    color: #e3e7ec;
  }

  #btn {
    padding-top: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 24px;
    grid-column-gap: 16px;
  }

  #slidetext1 {
    color: black;
  }

  #slidetext2 {
    color: rgb(255, 255, 255);
  }

  #slideimg1 {
    display: none;
  }
  #slideimg2 {
    display: none;
  }
  #body {
    padding: 0;
    margin: 0;
    padding-top: 30px;
  }
}
@media (max-width: 970px) {
}
@media (max-width: 688px) {
  #slider {
    margin-top: 137px;
  }
}
@media (max-width: 356px) {
  #slider {
    margin-top: 185px;
  }
  #btn {
    display: flex;
    flex-wrap: wrap;
    grid-row-gap: 12px;
  }
}
</style>
