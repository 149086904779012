<template>
  <div id="requestform">
    <v-icon x-large>mdi-phone</v-icon>
    <h1 id="titlenoline">{{ $t("message.ReqProd") }}</h1>
    <div id="req-contentform">
      <div id="form">
        <form>
          {{ $t("message.YourName") }}
          <div id="flex">
            <v-text-field
              v-model="message.name"
              :counter="30"
              label="Name"
              required
              @input="$v.name.$touch()"
              @blur="$v.name.$touch()"
            ></v-text-field>
            <v-icon large>mdi-human-male</v-icon>
          </div>
          {{ $t("message.YourPhone") }}
          <div id="flex">
            <v-text-field
              v-model="message.title"
              :items="items"
              label="Phone"
              required
              @change="$v.select.$touch()"
              @blur="$v.select.$touch()"
            ></v-text-field>
            <v-icon large>mdi-phone</v-icon>
          </div>
          {{ $t("message.YourEmail") }}
          <div id="flex">
            <v-text-field
              v-model="message.emailField"
              :error-messages="emailErrors"
              label="E-mail"
              @input="$v.email.$touch()"
              @blur="$v.email.$touch()"
            ></v-text-field>
            <v-icon large>mdi-email</v-icon>
          </div>

          {{ $t("message.YourProduct") }}
          <div id="flex">
            <v-text-field
              v-model="message.message"
              label="Product"
              @input="$v.product.$touch()"
              @blur="$v.product.$touch()"
            ></v-text-field>
            <v-icon large>mdi-pen</v-icon>
          </div>
          <!-- <v-checkbox
              v-model="checkbox"
              :error-messages="checkboxErrors"
              label="Do you agree?"
              required
              @change="$v.checkbox.$touch()"
              @blur="$v.checkbox.$touch()"
            ></v-checkbox> -->

          <v-btn id = "btnblueform"          
            
            class="mr-4"
            v-on:click="SendEmail"
            @click="alertMsg()"
          >
            {{ $t("message.Submit") }}
          </v-btn>
          <v-btn @click="clear">
            {{ $t("message.Clear") }}
          </v-btn>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
const axios = require("axios").default;

export default {
  mixins: [validationMixin],
  validations: {
    name: { required, maxLength: maxLength(30) },
    email: { email },
    select: {},
    checkbox: {
      checked(val) {
        return val;
      },
    },
  },
  data: () => ({
    // name: "",
    // email: "",
    select: null,
    items: ["Item 1", "Item 2", "Item 3", "Item 4"],
    checkbox: false,
    config: "",
    message: {
      name: "",
      emailField: "",
      title: "",
      message: "",
    },
  }),
  computed: {
    checkboxErrors() {
      const errors = [];
      if (!this.$v.checkbox.$dirty) return errors;
      !this.$v.checkbox.checked && errors.push("You must agree to continue!");
      return errors;
    },
    selectErrors() {
      const errors = [];
      if (!this.$v.select.$dirty) return errors;
      !this.$v.select.required && errors.push("");
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.maxLength &&
        errors.push("Name must be at most 10 characters long");
      !this.$v.name.required && errors.push("");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      return errors;
    },
  },

  methods: {
    submit() {
      this.$v.$touch();
      
    },
    clear() {
      this.$v.$reset();
      this.message.name = "";
      this.message.emailField = "";
      this.message.title = "";
      this.message.message = "";
      this.message.select = null;
      this.message.checkbox = false;
      
    },
    alertMsg(){
      if (this.$i18n.locale === "ru") {
        alert('Ваш запрос отправлен, мы свяжемся с вами в ближайшее время!')
      } else if (this.$i18n.locale === "gb") {
         alert('Your request has been sent, we will contact you as soon as possible!')
      } else if (this.$i18n.locale === "de") {
         alert('Ihre Anfrage wurde gesendet, wir werden uns schnellstmöglich mit Ihnen in Verbindung setzen!')
      }
    },
    async SendEmail() {
      await this.$store.dispatch("getConfig");
      this.config = await this.$store.getters.getConfig;
      let request = axios.post(this.config + "/SendEmail", this.message);
      // let request = await axios.post('http://localhost:5542/api/Samupak/SendEmail',this.message)
      if (request.status && request.data) {
        this.message = {
          name: "",
          emailField: "",
          title: "",
          message: "",
        };
      } else {
        console.log("error sending feedback");
      }
    },
  },
};
</script>

<style>
#feedback-marg {
  margin-left: 50px;
  margin-right: 50px;
  max-width: 400px;
  max-height: 800px;
  background-color: white;
}
#fb-bg {
  background-color: white;
}
#requestform {
  padding-top: 20px;
  padding-bottom: 20px;
}
#titlenoline {
  font-size: 24px;
  letter-spacing: normal;
  display: inline;
  margin-left: 10px;
  font: 26px Ubuntu, Arial, sans-serif;
  font-weight: 500;
}
#form {
  padding-top: 50px;
}
#flex {
  display: flex;
}
#btnblueform{
  background-color: #00569c;
  color: #e3e7ec;
}
</style>