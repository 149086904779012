<template>
  <div class="MainLayout">
    
  </div>
</template>
<script>

export default {
  data: () => ({}),
  components: {
    
  },
  methods: {},
};
</script>