<template>
  <!-- Услуги -->
  <div id="services" class="chapter-no-line-spec">
    <div id="textcenter">
      <h1 id="naming">
        {{ $t("message.High") }}
        {{ $t("message.ValueAdd") }}
        {{ $t("message.ForBusiness") }}
      </h1>
    </div>
    <div id="serv-container">
      <img
        id="serv-img"
        height="700"
        width="600"
        :src="getImageUrlbyName('Watches')"
      />

      <div id="service-items">
        <v-card width="350" id="serv-card" shaped :loading="loading">
          <!-- <router-link to="/products/integration"> -->
          <img
            width="150"
            height="150"
            :src="getImageUrlbyName('Serv1')"
            href=""
          />
          <div id="cardtitle">{{ $t("message.IncreaseProfit") }}</div>
          <!-- </router-link> -->
          <div id="cardtext">
            {{ $t("message.IncreaseProfitText") }}
          </div>
        </v-card>
        <v-card width="350" id="serv-card" shaped :loading="loading">
          <!-- <router-link to="/products/refact"> -->
          <img
            width="150"
            height="150"
            :src="getImageUrlbyName('Serv2')"
            href=""
          />
          <div id="cardtitle">{{ $t("message.Maximize") }}</div>
          <!-- </router-link> -->
          <div id="cardtext">
            {{ $t("message.MaximizeText") }}
          </div>
        </v-card>
        <v-card width="350" id="serv-card" shaped :loading="loading">
          <!-- <router-link to="/products/audit"> -->
          <img
            width="150"
            height="150"
            :src="getImageUrlbyName('Serv3')"
            href=""
          />
          <div id="cardtitle">{{ $t("message.MakeBetter") }}</div>
          <!-- </router-link> -->
          <div id="cardtext">
            {{ $t("message.MakeBetterText") }}
          </div>
        </v-card>
        <v-card width="350" id="serv-card" shaped :loading="loading">
          <!-- <router-link to="/products/delivery"> -->
          <img
            width="150"
            height="150"
            :src="getImageUrlbyName('Serv4')"
            href=""
          />
          <div id="cardtitle">{{ $t("message.AchieveHigh") }}</div>
          <!-- </router-link> -->
          <div id="cardtext">
            {{ $t("message.AchieveHighText") }}
          </div>
        </v-card>
        <v-card width="350" id="serv-card" shaped :loading="loading">
          <!-- <router-link to="/products/benztruck"> -->
          <img
            width="150"
            height="150"
            :src="getImageUrlbyName('Serv5')"
            href=""
          />
          <div id="cardtitle">{{ $t("message.SolveComplexity") }}</div>
          <!-- </router-link> -->
          <div id="cardtext">
            {{ $t("message.SolveComplexityText") }}
          </div>
        </v-card>
        <v-card width="350" id="serv-card" shaped :loading="loading">
          <!-- <router-link to="/products/merch"> -->
          <img
            width="150"
            height="150"
            :src="getImageUrlbyName('Serv6')"
            href=""
          />
          <div id="cardtitle">{{ $t("message.Situational") }}</div>
          <!-- </router-link> -->
          <div id="cardtext">
            {{ $t("message.SituationalText") }}
          </div>
        </v-card>
        <v-card width="350" id="serv-card" shaped :loading="loading">
          <!-- <router-link to="/products/trash"> -->
          <img
            width="150"
            height="150"
            :src="getImageUrlbyName('Serv7')"
            href=""
          />
          <div id="cardtitle">{{ $t("message.ReduceHuman") }}</div>
          <!-- </router-link> -->
          <div id="cardtext">
            {{ $t("message.ReduceHumanText") }}
          </div>
        </v-card>
        <v-card width="350" id="serv-card" shaped :loading="loading">
          <!-- <router-link to="/products/trash"> -->
          <img
            width="150"
            height="150"
            :src="getImageUrlbyName('Serv8')"
            href=""
          />
          <div id="cardtitle">{{ $t("message.ReachIndiv") }}</div>
          <!-- </router-link> -->
          <div id="cardtext">
            {{ $t("message.ReachIndivText") }}
          </div>
        </v-card>
        <v-card width="350" id="serv-card" shaped :loading="loading">
          <!-- <router-link to="/products/trash"> -->
          <img
            width="150"
            height="150"
            :src="getImageUrlbyName('Serv9')"
            href=""
          />
          <div id="cardtitle">{{ $t("message.LowerCO2") }}</div>
          <!-- </router-link> -->
          <div id="cardtext">
            {{ $t("message.LowerCO2Text") }}
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  computed: mapState({
    loading: (state) => state.loader.loading,
  }),
  methods: mapMutations(["reserve"]),
  async mounted() {
    this.reserve();
  },
};
</script>

<style>
/* Дополнительные услуги */
#textcenter {
  text-align: center;
}
#serv-container {
  display: flex;
}
#serv-img {
    align-self: center;
    
  }
.chapter {
  height: 100%;
  width: 100%;
  padding-left: 20%;
  padding-right: 20%;
  padding-top: 40px;
}
.chapter-no-line-spec {
  height: 100%;
  width: 100%;
  padding-top: 40px;
}
.chapter-no-line {
  height: 100%;
  width: 100%;
  padding-left: 20%;
  padding-right: 20%;
  padding-top: 40px;
}
.chapter::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  margin-top: 10px;
  background-color: #1a1a1a;
}
#naming {
  font-size: 43px;
  letter-spacing: normal;
  display: inline;
  color: #54595f;
  text-align: center;
  /* border-bottom: 2px solid #00569c; */
}

/* #naming::after {
  content: "";
  display: block;
  min-width: 10px;
  max-width: 300px;
  height: 2px;
  margin-top: 10px;
  background-color: #00569c;
} */

#servtext {
  font-size: 13px;
  padding-top: 20px;
}

#service-items {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-right: 5%;
}

#serv-card {
  margin-top: 20px;
  max-width: 350px;
}
#cardtitle {
  font-size: 26px;
  font-weight: 500;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 8px;
  text-decoration: none;
  color: #000102;
}
#cardtitle:hover {
  text-decoration: none;
  color: black;
}
#cardtext {
  font-size: 14px;
  margin-left: 8px;
  color: rgb(0, 0, 0);
}

@media (max-width: 1220px) {
  .chapter {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 40px;
  }
  .chapter-no-line {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 40px;
  }
  #naming {
    font-size: 30px;
  }
  #service-items {
    justify-content: center;
  }
}
@media (max-width: 1030px) {
  #serv-container {
    display: flex;
    flex-direction: column;
  }
  #service-items {
    margin-right: 0%;
  }
}
.svg-wrapper {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  width: 320px;
}
.shape {
  stroke-dasharray: 140 540;
  stroke-dashoffset: -474;
  stroke-width: 8px;
  fill: transparent;
  stroke: #19f6e8;
  border-bottom: 5px solid black;
  transition: stroke-width 1s, stroke-dashoffset 1s, stroke-dasharray 1s;
}
.text {
  font-family: "Roboto Condensed";
  font-size: 22px;
  line-height: 32px;
  letter-spacing: 8px;
  color: #fff;
  top: -48px;
  position: relative;
}
.svg-wrapper:hover .shape {
  stroke-width: 2px;
  stroke-dashoffset: 0;
  stroke-dasharray: 760;
}
/* @media (max-width: 750px) {
  #naming {
    font-size: 20px;
  }
} */
</style>