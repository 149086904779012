export default {
    actions: {
        // getTextState(dispatch, commit, getters, rootGetters) {
            
        //     getters.someGetter // -> 'foo/someGetter'
        //     rootGetters.someGetter // -> 'someGetter'
        //     rootGetters['textblocks/getTextblock'] // -> 'bar/someGetter'
        //     this.textblock = getters.getTextblock
        //     commit('load', null, { root: true })
        // },

    },
    mutations: {
        load(state) {
            setTimeout(() => (state.messageLoading = true), 1000)
        },
    },
    state: {
        messageLoading: false,
        
    },
    getters: {
    }
}