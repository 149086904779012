import Vue from "vue";
import VueI18n from "vue-i18n";
// import localizations from "./localizations";

Vue.use(VueI18n);
const messages = {};
const i18n = new VueI18n({
  lazy: true,
  locale: 'ru',
  // fallbackLocale: 'ru',
  messages,
  
});

export default { i18n };
