import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import Vuex from "vuex"
import i18n from "./plugins/i18n"
import CountryFlag from "vue-country-flag"
import '@mdi/font/css/materialdesignicons.css'
import Vuelidate from 'vuelidate'
import '../node_modules/pikaday/css/pikaday.css';
import VuePikaday from '@netcz/vue-pikaday';
import titleMixin from './mixins/titleMixin'

Vue.mixin({
  // async mounted() {
  //   await this.getPictureblock();
  // },
  methods: {
    async getPictureblock() {
      // await this.$store.dispatch("getAllPictureblocks");
    },
    getImageUrlbyName(name, img) {
      img = this.$store.getters.getPictureblocks.find(
        (item) => item.name == name
      );
      // console.log(img);
      return "data:image/jpeg;base64," + img.picture;
    },
  },
}),
Vue.mixin(titleMixin)

Vue.component("country-flag", CountryFlag)
Vue.use(Vuex)
Vue.use(Vuetify)
Vue.use(BootstrapVue)
Vue.config.productionTip = false
Vue.use(Vuelidate)
Vue.use(VuePikaday);

export default new Vuetify({
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
  async mounted() {
    this.$store.dispatch("getTextblock");
    Vue.prototype.$messages = this.$store.getters.getTextblock;
  }
})

new Vue({
  vuetify: new Vuetify(),
  store,
  router,
  Vuetify,
  Vuex,
  i18n,
  VuePikaday,
  
  // localizations,
  components: {},
  render: h => h(App)
}).$mount('#app')



